import { EnvMode, IEnv } from './env.interfaces';

const processEnvMode = process.env.NODE_ENV?.toLowerCase() as EnvMode;
const envMode = Object.values(EnvMode).includes(processEnvMode) ? processEnvMode : EnvMode.DEV_ENV;

const isEnv = (mode: EnvMode) => envMode.toLowerCase() === mode;

export const getEnvMode = () => envMode;

export const isDevEnv = () => isEnv(EnvMode.DEV_ENV);

export const isProdEnv = () => isEnv(EnvMode.PROD_ENV);

export const isTestEnv = () => isEnv(EnvMode.TEST_ENV);

const mapEnvValues = {
  bool: (envValue: string) => envValue === 'true',
  number: (envValue: string, defaultValue: number) => {
    const value = Number(envValue);

    return Number.isNaN(value) ? defaultValue : value;
  },
};

const mapEnv = () => {
  const {
    DATABASE_HOST = '',
    DATABASE_USERNAME = '',
    DATABASE_PASSWORD = '',
    DATABASE_DATABASE = '',
    DATABASE_PORT = '',
    JWT_SECRET = '',
    EMAIL_JWT_SECRET = '',
    AUTH_TOKEN_EXPIRATION = '30m',
    REFRESH_TOKEN_EXPIRATION = '1d',
    OTP_TOKEN_EXPIRATION = '2h',
    AWS_REGION = '',
    AWS_ACCESS_KEY_ID = '',
    AWS_SECRET_ACCESS_KEY = '',
    LINKEDIN_SECRET = '',
    NEXT_PUBLIC_HUBSPOT_ACCOUNT_ID = '',
    DOMAIN = '',
    API_SECRET_KEY = '',
    SCHEDULE_API_URL = '',
    EMAILS_REMINDER_PERIOD = '',
    SLACK_WEBHOOK_URL = '',
    NEXT_PUBLIC_HEAP_TRACKING_ID = '',
    NEXT_PUBLIC_LOCATION_SERVICE_HOST = '',
    NEXT_PUBLIC_LOCATION_SERVICE_KEY = '',
    LINKEDIN_SCRAPER_TOKEN = '',
    SLACK_TOKEN = '',
    SENDGRID_API_KEY = '',
    SENDER_EMAIL = '',
  } = process.env;

  const defaultDbPort = 5432;

  const parsed: IEnv = {
    database: {
      host: DATABASE_HOST,
      port: mapEnvValues.number(DATABASE_PORT, defaultDbPort),
      username: DATABASE_USERNAME,
      password: DATABASE_PASSWORD,
      database: DATABASE_DATABASE,
    },
    jwtSecret: JWT_SECRET,
    emailJwtSecret: EMAIL_JWT_SECRET,
    authorizationTokenDuration: AUTH_TOKEN_EXPIRATION,
    refreshTokenDuration: REFRESH_TOKEN_EXPIRATION,
    awsBucketName: process.env.NEXT_PUBLIC_AWS_BUCKET_NAME ?? '',
    awsRegion: AWS_REGION,
    awsAccessKeyId: AWS_ACCESS_KEY_ID,
    awsSecretAccessKey: AWS_SECRET_ACCESS_KEY,
    cookiesAge: mapEnvValues.number(process.env.NEXT_PUBLIC_COOKIES_AGE ?? '', 28800),
    cookieDomain: process.env.NEXT_PUBLIC_COOKIES_DOMAIN,
    linkedInClientId: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID ?? '',
    linkedInSecret: LINKEDIN_SECRET,
    hubspotAccountId: NEXT_PUBLIC_HUBSPOT_ACCOUNT_ID,
    domain: DOMAIN,
    otpExpiration: OTP_TOKEN_EXPIRATION,
    locationsCacheAge: mapEnvValues.number(
      process.env.NEXT_PUBLIC_LOCATIONS_CACHE_AGE ?? '',
      86400000,
    ),
    apiSecret: API_SECRET_KEY,
    scheduleApiUrl: SCHEDULE_API_URL,
    emailsReminderPeriod: mapEnvValues.number(EMAILS_REMINDER_PERIOD, 1),
    slackWebhookUrl: SLACK_WEBHOOK_URL,
    heapTrackingId: NEXT_PUBLIC_HEAP_TRACKING_ID,
    locationServiceHost: NEXT_PUBLIC_LOCATION_SERVICE_HOST,
    locationServiceKey: NEXT_PUBLIC_LOCATION_SERVICE_KEY,
    linkedInScraperToken: LINKEDIN_SCRAPER_TOKEN,
    slackToken: SLACK_TOKEN,
    sendGridApiKey: SENDGRID_API_KEY,
    senderEmail: SENDER_EMAIL,
  };

  return Object.freeze(parsed);
};

export const env = mapEnv();

export default {
  isDevEnv,
  isProdEnv,
  isTestEnv,
  getEnvMode,
  env,
};
