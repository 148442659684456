import { UserRole } from '@/enums';
import dynamic from 'next/dynamic';
import { IRoute } from './types';

const AuthLayout = dynamic(() => import('./layouts/AuthLayout'), { ssr: true });
const EmptyLayout = dynamic(() => import('./layouts/EmptyLayout'), { ssr: true });
const AdminLayout = dynamic(() => import('./layouts/AdminLayout'), { ssr: true });

type IAvailableRouteKeys =
  | 'untilJobIsPosted'
  | 'untilCandidateOnboardingFinished'
  | 'untilCandidateIsVerified';

export const availableRoutes: Record<IAvailableRouteKeys, string[]> = {
  untilJobIsPosted: [
    '/post-job',
    '/onboarding/employer',
    '/profile,',
    '/my-jobs',
    '/candidates',
    '/candidates/[candidateId]',
  ],
  untilCandidateOnboardingFinished: ['/', '/my-jobs', '/jobs', '/jobs/[jobId]'],
  untilCandidateIsVerified: ['/', '/my-jobs', '/jobs', '/jobs/[jobId]', '/profile'],
} as Record<IAvailableRouteKeys, Array<keyof typeof routes>>;

const routes = {
  '/_error': { isAuthorized: null, tabTitle: 'Error' } as IRoute,
  '/404': { isAuthorized: null, tabTitle: 'Not Found' } as IRoute,
  '/': { isAuthorized: true, tabTitle: 'Alariss' } as IRoute,
  '/linkedIn-authorized': {
    layout: EmptyLayout,
    isAuthorized: false,
    tabTitle: 'LinkedIn Authorized',
  } as IRoute,
  '/login': { layout: AuthLayout, isAuthorized: false, tabTitle: 'Login' } as IRoute,
  '/signup': { layout: AuthLayout, isAuthorized: false, tabTitle: 'Sign up' } as IRoute,
  '/onboarding/candidate': {
    isAuthorized: true,
    permissions: [UserRole.Candidate],
    tabTitle: 'Onboarding',
  } as IRoute,
  '/forgot': { layout: AuthLayout, isAuthorized: false, tabTitle: 'Forgot password' } as IRoute,
  '/reset-password': {
    layout: AuthLayout,
    isAuthorized: false,
    tabTitle: 'Reset password',
  } as IRoute,
  '/r/[[...redirect]]': { isAuthorized: null } as IRoute,
  '/onboarding/employer': {
    layout: AuthLayout,
    isAuthorized: true,
    permissions: [UserRole.MasterEmployer, UserRole.AdminEmployer],
    tabTitle: 'Onboarding',
  } as IRoute,
  '/profile': {
    isAuthorized: true,
    permissions: [
      UserRole.Candidate,
      UserRole.MasterEmployer,
      UserRole.Employer,
      UserRole.AdminEmployer,
    ],
    tabTitle: 'Profile',
  } as IRoute,
  '/terms-of-service': { isAuthorized: null, tabTitle: 'Terms of service' } as IRoute,
  '/post-job': {
    isAuthorized: true,
    permissions: [UserRole.MasterEmployer, UserRole.Employer, UserRole.AdminEmployer],
    tabTitle: 'Post job',
  } as IRoute,
  '/manage-company': {
    isAuthorized: true,
    permissions: [UserRole.MasterEmployer, UserRole.AdminEmployer],
    tabTitle: 'Manage Company',
  } as IRoute,
  '/my-jobs': {
    isAuthorized: true,
    permissions: [
      UserRole.MasterEmployer,
      UserRole.Employer,
      UserRole.Candidate,
      UserRole.AdminEmployer,
    ],
    tabTitle: 'My Jobs',
  } as IRoute,
  '/my-jobs/edit/[jobId]': {
    isAuthorized: true,
    permissions: [UserRole.MasterEmployer, UserRole.Employer, UserRole.AdminEmployer],
    tabTitle: 'Edit Job',
  } as IRoute,
  '/my-jobs/matches/[jobId]': {
    layout: EmptyLayout,
    isAuthorized: true,
    permissions: [UserRole.MasterEmployer, UserRole.Employer, UserRole.AdminEmployer],
    tabTitle: 'Job Matches',
  } as IRoute,
  '/my-jobs/[tabName]': {
    isAuthorized: true,
    permissions: [UserRole.Candidate],
  } as IRoute,
  '/jobs': { isAuthorized: true, permissions: [UserRole.Candidate], tabTitle: 'Jobs' } as IRoute,
  '/jobs/[jobId]': { isAuthorized: null, tabTitle: 'Job' } as IRoute,
  '/candidates': {
    isAuthorized: true,
    permissions: [UserRole.MasterEmployer, UserRole.Employer, UserRole.AdminEmployer],
    tabTitle: 'Candidates',
  } as IRoute,
  '/candidates/[candidateId]': {
    isAuthorized: null,
    tabTitle: 'Candidate',
  } as IRoute,
  '/admin': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Alariss',
  } as IRoute,
  '/admin/imports': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Imports',
  } as IRoute,
  '/admin/candidates': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Candidates',
  } as IRoute,
  '/admin/email-templates': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Emails',
  } as IRoute,
  '/admin/employers': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Employers',
  } as IRoute,
  '/admin/employers/[employerId]': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Edit Employer',
  } as IRoute,
  '/admin/companies': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Companies',
  } as IRoute,
  '/admin/companies/[companyId]': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Edit Company',
  } as IRoute,
  '/admin/jobs': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Jobs',
  } as IRoute,
  '/admin/jobs/[jobId]': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Job',
  } as IRoute,
  '/admin/jobs/[jobId]/matches': {
    layout: EmptyLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Job Matches',
  } as IRoute,
  '/admin/job-manage/[companyId]': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Edit job',
  } as IRoute,
  '/admin/profile': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Profile',
  } as IRoute,
  '/admin/candidates/[candidateId]/matches': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Candidate Matches',
  } as IRoute,
  '/admin/candidates/[candidateId]': {
    layout: AdminLayout,
    isAuthorized: true,
    permissions: [UserRole.Admin],
    tabTitle: 'Candidate',
  } as IRoute,
  '/interview': { isAuthorized: null, tabTitle: 'Interview' } as IRoute,
} as const;

export default routes as Record<string, IRoute>;
