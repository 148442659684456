const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again.';
const UNABLE_TO_CONNECT_TO_LINKEDIN = 'Unable to connect to LinkedIn. Please try again.';
const INVALID_TOKEN = 'Invalid verification token.';
const EMAIL_VERIFIED = 'Email verifed!';
const EMAIL_VERIFICATION_FAILED = 'Email verification failed!';
const APPLICATION_DECLINED = 'Application declined!';
const INTERVIEW_CONFIRMED = 'Interview confirmed!';
const INTERVIEW_DECLINED = 'Interview declined!';
const JOB_ARCHIVED = 'Job archieved!';
const DATA_IMPORT_STARTED = 'Data import has been started.';
const UNKNOWN_JOB = 'Unknown job.';
const VERIFICATION_EMAIL_SENT = 'Your verification email has been sent to your new address.';
const SETTINGS_UPDATED = 'Settings has been updated successfully!';
const URLS_UPDATED = 'Candidate URLS has been updated successfully!';
const INTERVIEW_REQUESTED = 'Interview has been requested.';
const OPEN_TO_OFFERS_UPDATED = 'Open to Offers status has been updated successfully!';
const PROFILE_UPDATED = 'Profile has been updated successfully!';
const PROFILE_IMAGE_UPDATED = 'Avatar has been updated successfully!';
const COMPANY_LOGO_UPDATED = 'Company logo has been updated successfully!';
const USER_ALREADY_EXISTS = 'User already exists';
const EMPTY_COMPANY_INFO = 'Empty company info, make sure that employer filled it';
const INTERVIEW_EMAIL_HAS_BEEN_SEND =
  "We've sent you an email to schedule an interview. Please check your email.";
const TERMS_SIGNED_UPDATED = 'Terms signed status has been updated successfully!';
const TERMS_URI_UPDATED = 'Terms link has been updated successfully!';
const LINK_COPIED = 'Link copied';
const JOB_STATUS_UPDATED = 'Job status has been updated successfully!';
const EMAIL_TEMPLATE_UPDATE = 'Email template has been updated successfully!';
const HUBSPOT_ID_UPDATED = 'Hubspot ID has been updated successfully!';
const INVITATION_EMAIL_SENT = 'Invitation email has been sent.';
const NEW_COLLEAGUE_ADDED = 'New colleague has been successfully added.';
const MATCH_STATUS_UPDATED = 'Match status has been updated successfully!';
const MATCH_ADDED = 'New match has been added.';
const RESERVED_UPDATED = 'Reserved status has been updated successfully!';
const MATCH_STAGE_UPDATED = 'Match stage has been updated.';
const MATCH_STAGE_CANNOT_BE_CHANGED = 'Match stage can not be updated.';
const INTERVIEWER_ADDED = 'Interviewer added.';
const CANDIDATE_VERTICALS_UPDATED = 'Candidate verticals have been updated.';
const CANDIDATE_SALES_QUALIFICATIONS_UPDATED = 'Candidate sales qualifications have been updated.';
const JOB_MATCH_NOTE_CREATED = 'Note has been created successfully!';
const JOB_MATCH_NOTE_UPDATED = 'Note has been updated successfully!';
const JOB_MATCH_NOTE_DELETED = 'Note has been deleted successfully!';
const JOB_MATCH_CLIENT_FEEDBACK_EDITED = 'Client feedback has been updated successfully!';
const JOB_MATCH_ADMIN_MESSAGE_EDITED = 'The admin message has been updated successfully!';
const INTERVIEW_STATUS_UPDATED = 'Interview status has been updated successfully!';
const INTERVIEW_DATE_UPDATED = 'Interview date has been updated successfully!';
const MATCH_ADMIN_ASSIGNED = 'Admin have been assigned to match successfully!';
const JOB_MATCH_NOTE_CLIENT_VISIBILITY_UPDATED =
  'Note client visibility have been updated successfully!';
const INTERVIEW_LINK_UPDATED = 'Interview link has been updated successfully!';
const SCHEDULE_LINK_UPDATED = 'Schedule link has been updated successfully!';
const JOB_MATCH_NOTE_ATTACHMENT_DELETED = 'Note attachment have been deleted successfully!';
const JOB_MATCH_NOTE_ATTACHMENTS_UPDATED = 'Note attachments have been updated successfully!';
const NEW_CANDIDATE_ADDED_SUCCESSFULLY = 'New candidate added successfully!';
const LINKEDIN_PARSE_ERROR =
  'LinkedIn parse error. Please create candidate profile manually by logging out and going to www.talent.alariss.com/signup to create a profile on behalf of the user';
const REJECTED_FEEDBACK_UPDATED = 'Rejected feedback has been updated succesfully!';
const ADMIN_CREATED_SUCCESSFULLY = 'New admin created successfully!';

export default {
  somethingWentWrong: SOMETHING_WENT_WRONG,
  unableToConnectToLinkedIn: UNABLE_TO_CONNECT_TO_LINKEDIN,
  invalidToken: INVALID_TOKEN,
  emailVerified: EMAIL_VERIFIED,
  emailVerificationFailed: EMAIL_VERIFICATION_FAILED,
  applicationDeclined: APPLICATION_DECLINED,
  interviewConfirmed: INTERVIEW_CONFIRMED,
  interviewDeclined: INTERVIEW_DECLINED,
  interviewStatusUpdated: INTERVIEW_STATUS_UPDATED,
  interviewDateUpdated: INTERVIEW_DATE_UPDATED,
  jobArchived: JOB_ARCHIVED,
  dataImportStarted: DATA_IMPORT_STARTED,
  unknownJob: UNKNOWN_JOB,
  verificationEmailSent: VERIFICATION_EMAIL_SENT,
  settingsUpdated: SETTINGS_UPDATED,
  urlsUpdated: URLS_UPDATED,
  interviewRequested: INTERVIEW_REQUESTED,
  openToOffersUpdated: OPEN_TO_OFFERS_UPDATED,
  profileUpdated: PROFILE_UPDATED,
  profileImageUpdated: PROFILE_IMAGE_UPDATED,
  companyLogoUpdated: COMPANY_LOGO_UPDATED,
  userAlreadyExists: USER_ALREADY_EXISTS,
  emptyCompanyInfo: EMPTY_COMPANY_INFO,
  interviewEmailHasBeenSent: INTERVIEW_EMAIL_HAS_BEEN_SEND,
  termsSignedStatusUpdated: TERMS_SIGNED_UPDATED,
  termsUriUpdated: TERMS_URI_UPDATED,
  linkCopied: LINK_COPIED,
  jobStatusUpdated: JOB_STATUS_UPDATED,
  emailTemplateUpdated: EMAIL_TEMPLATE_UPDATE,
  hubspotIdUpdated: HUBSPOT_ID_UPDATED,
  invitationEmailSent: INVITATION_EMAIL_SENT,
  newColleagueAdded: NEW_COLLEAGUE_ADDED,
  matchStatusUpdated: MATCH_STATUS_UPDATED,
  matchAdded: MATCH_ADDED,
  reservedUpdated: RESERVED_UPDATED,
  matchStageUpdated: MATCH_STAGE_UPDATED,
  matchStageCannotBeUpdated: MATCH_STAGE_CANNOT_BE_CHANGED,
  interviewerAdded: INTERVIEWER_ADDED,
  candidateVerticalsUpdated: CANDIDATE_VERTICALS_UPDATED,
  candidateSalesQualificationsUpdated: CANDIDATE_SALES_QUALIFICATIONS_UPDATED,
  jobMatchNoteCreated: JOB_MATCH_NOTE_CREATED,
  jobMatchNoteUpdated: JOB_MATCH_NOTE_UPDATED,
  jobMatchNoteDeleted: JOB_MATCH_NOTE_DELETED,
  jobMatchClientFeedbackEdited: JOB_MATCH_CLIENT_FEEDBACK_EDITED,
  jobMatchAdminMessageEdited: JOB_MATCH_ADMIN_MESSAGE_EDITED,
  matchAdminAssigned: MATCH_ADMIN_ASSIGNED,
  jobMatchNoteClientVisibilityUpdated: JOB_MATCH_NOTE_CLIENT_VISIBILITY_UPDATED,
  interviewLinkUpdated: INTERVIEW_LINK_UPDATED,
  scheduleLinkUpdated: SCHEDULE_LINK_UPDATED,
  jobMatchNoteAttachmentDeleted: JOB_MATCH_NOTE_ATTACHMENT_DELETED,
  jobMatchNoteAttachmentsUpdated: JOB_MATCH_NOTE_ATTACHMENTS_UPDATED,
  newCandidateAddedSuccessfully: NEW_CANDIDATE_ADDED_SUCCESSFULLY,
  linkedinParseError: LINKEDIN_PARSE_ERROR,
  rejectedFeedbackUpdated: REJECTED_FEEDBACK_UPDATED,
  adminCreatedSuccessfully: ADMIN_CREATED_SUCCESSFULLY,
};
